<script>
import { extractShopUrlFromString } from '@/features/shops/services/shopSearchService'
import SuggestNewItemCard from '@/infrastructure/pwa/components/SuggestNewItemCard'

const urlRegex = /(?:https?|chrome):\/\/[^\s$.?#].[^\s]*/i

export default {
  components: { SuggestNewItemCard },
  computed: {
    shareTitle() {
      return this.$route.query.title
    },

    shareText() {
      return this.$route.query.text
    },

    shareUrl() {
      return this.$route.query.url
    },

    // shareTextLines() {
    //   return this.$route.query.text.split('\n')
    // },
    // shareText() {
    //   return this.shareTextLines && this.shareTextLines.length > 0 ? this.shareTextLines[0] : ''
    // },
    // shareLink() {
    //   return this.shareTextLines && this.shareTextLines.length > 1 ? this.shareTextLines[1] : ''
    // },

    allUrls() {
      const urls = []

      if (this.shareText) {
        const matchedUrls = urlRegex.exec(String(this.shareText))
        if (matchedUrls && matchedUrls.length) {
          urls.push(...matchedUrls)
        }
      }

      if (this.shareUrl) {
        const matchedUrls = urlRegex.exec(String(this.shareUrl))
        if (matchedUrls && matchedUrls.length) {
          urls.push(...matchedUrls)
        }
      }

      return urls
    },

    foundPotentialShopProduct() {
      return this.allUrls.map((phrase) => extractShopUrlFromString(phrase)).find((url) => !!url)
    },

    suggestions() {
      const suggestions = []

      if (this.foundPotentialShopProduct) {
        suggestions.push({
          type: 'potential-shop-product',
          data: this.foundPotentialShopProduct,
        })
      }

      return suggestions
    },

    hasSuggestions() {
      return this.suggestions.length > 0
    },
  },
}
</script>

<template>
  <div>
    <div v-if="hasSuggestions">
      <div v-for="(suggestion, i) in suggestions" :key="i">
        <SuggestNewItemCard
          v-if="suggestion.type === 'potential-shop-product'"
          :url="suggestion.data.url"
          :shop="suggestion.data.shop"
          :icon="suggestion.data.icon"
        />

        <v-card v-else>
          <v-card-text>Unsupported type of suggestion: {{ suggestion.type }}</v-card-text>
        </v-card>
      </div>
    </div>
    <v-card v-else>
      <v-card-title>Something received</v-card-title>
      <v-card-subtitle>Something received but we don't know what to do with it</v-card-subtitle>
      <v-card-text>
        <div><strong>Title</strong></div>
        <div>{{ shareTitle }}</div>
        <div>&nbsp;</div>
        <div><strong>Text</strong></div>
        <div>{{ shareText }}</div>
      </v-card-text>
    </v-card>
  </div>
</template>
