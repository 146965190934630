import router from '@/infrastructure/router'
import { computed, onMounted, onUnmounted, reactive, watch } from '@vue/composition-api'

const state = reactive({
  currentRoute: null,
  params: {},
  query: {},
})

router.afterEach((route) => {
  state.currentRoute = route
  state.params = route.params
  state.query = route.query
})

function queryIsSame(oldQuery, newQuery) {
  if (!oldQuery && !newQuery) return true

  if (oldQuery && !newQuery) return false
  if (!oldQuery && newQuery) return false

  const oldKeys = Object.keys(oldQuery)
  const newKeys = Object.keys(newQuery)

  if (oldKeys.length !== newKeys.length) return false

  for (let i = 0; i < oldKeys.length; i++) {
    const key = oldKeys[i]
    if (oldQuery[key] !== newQuery[key]) return false
  }

  return true
}

const setQueryParam = (name, newValue, { replace } = {}) => {
  const oldQuery = state.query

  const newQuery = Object.assign({}, state.query || {})

  if (newValue) {
    newQuery[name] = newValue
  } else {
    delete newQuery[name]
  }

  if (queryIsSame(oldQuery, newQuery)) {
    return
  }

  if (replace) {
    return router.replace({ query: newQuery })
  } else {
    return router.push({ query: newQuery })
  }
}

const syncDialogOpenBooleanToQuery = ({ queryKey, closeDialogFunc }) => {
  const currentRoute = computed(() => state.currentRoute)
  const query = computed(() => state.query)

  onMounted(() => {
    setQueryParam(queryKey, true)
  })

  onUnmounted(() => {
    setQueryParam(queryKey, undefined, { replace: true })
  })

  watch(currentRoute, () => {
    if (!query.value || !query.value[queryKey]) {
      if (closeDialogFunc) {
        closeDialogFunc()
      }
    }
  })
}

export const useRouterManager = () => ({
  currentRoute: computed(() => state.currentRoute),
  params: computed(() => state.params),
  query: computed(() => state.query),

  setQueryParam,
  syncDialogOpenBooleanToQuery,
})
