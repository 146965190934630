<script>
import store from '@/infrastructure/store'
import FriendPickerDialog from '@/features/friends/components/FriendPickerDialog'

export default {
  components: {
    FriendPickerDialog,
  },

  props: {
    url: { type: String, required: true },
    shop: { type: String },
    icon: { type: String },
  },

  data() {
    return {
      isLoading: false,
      itemDetails: undefined,

      isAddingMyItem: false,
      isAddingFriendItem: false,

      showAddFriendsDialog: false,
    }
  },

  mounted() {
    this.autoDetectFromShop()
  },

  methods: {
    async autoDetectFromShop() {
      this.isLoading = true
      try {
        this.itemDetails = await store.dispatch('items/autoDetectItemDetailsFromShopUrl', { shopUrl: this.url })
      } finally {
        this.isLoading = false
      }
    },

    async addMyItem() {
      if (!this.itemDetails) {
        alert('Cannot addMyItem, itemDetails is not specified')
        return
      }

      this.isAddingMyItem = true
      try {
        const addedMyItem = await store.dispatch('items/addMyItem', {
          item: {
            name: this.itemDetails.name,
            price: this.itemDetails.price,
            shop: this.itemDetails.shop,
            links: [this.itemDetails.url],
            rating: 0,
            // images: [this.itemDetails.imageUrl], //TODO: upload first to get ImageId from API
          },
        })

        store.commit('addToast', {
          message: 'My item successfully added',
          color: 'success',
          actionText: 'View',
          actionFunction: () => {
            this.$router.push({
              name: 'my-items',
              query: { highlightedItemId: addedMyItem.id },
            })
          },
        })
      } finally {
        this.isAddingMyItem = false
      }
    },

    async onFriendPicked(friend) {
      this.showAddFriendsDialog = false

      if (!this.itemDetails) {
        alert('Cannot friendItem, itemDetails is not specified')
        return
      }

      this.isAddingFriendItem = true
      try {
        const addedFriendItem = await store.dispatch('items/addFriendItem', {
          userId: friend.friendUserId,
          item: {
            name: this.itemDetails.name,
            price: this.itemDetails.price,
            shop: this.itemDetails.shop,
            links: [this.itemDetails.url],
            rating: 0,
            // images: [this.itemDetails.imageUrl], //TODO: upload first to get ImageId from API
          },
        })

        store.commit('addToast', {
          message: `Surprise item added for ${friend.friendFullName}`,
          color: 'success',
          actionText: 'View',
          actionFunction: () => {
            this.$router.push({
              name: 'friend-with-items',
              params: { userId: friend.friendUserId },
              query: { highlightedItemId: addedFriendItem.id },
            })
          },
        })
      } finally {
        this.isAddingFriendItem = false
      }
    },
    onPickFriendDialogCanceled() {
      this.showAddFriendsDialog = false
    },
  },
}
</script>

<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <div class="d-flex align-center">
        <div v-if="icon" class="mr-2">
          <v-img :src="icon" width="20" />
        </div>
        <div>{{ shop }} product detected</div>
      </div>
    </v-card-title>
    <v-card-text> Do you want to create a new item from this takealot product?</v-card-text>
    <v-card-text v-if="isLoading"> Busy loading item details</v-card-text>

    <div class="px-4 pb-4">
      <v-card v-if="!isLoading && itemDetails">
        <div class="d-flex align-center">
          <div>
            <v-card-title class="pb-0">{{ itemDetails.name }}</v-card-title>
            <v-card-text class="pt-0">
              <div class="success--text">
                <v-icon small color="success">mdi-cart-outline</v-icon>
                {{ itemDetails.shop }}
              </div>

              <div>R {{ itemDetails.price }}</div>
            </v-card-text>
          </div>
          <div>
            <v-img v-if="itemDetails.imageUrl" :src="itemDetails.imageUrl" width="70" />
          </div>
        </div>

        <v-card-actions>
          <v-btn text :loading="isAddingMyItem" :disabled="isAddingMyItem" @click="addMyItem">Add my item</v-btn>
          <v-spacer />
          <v-btn text :loading="isAddingFriendItem" :disabled="isAddingFriendItem" @click="showAddFriendsDialog = true"> Add friend item </v-btn>
        </v-card-actions>

        <FriendPickerDialog v-if="showAddFriendsDialog" @picked="onFriendPicked" @canceled="onPickFriendDialogCanceled">
          <template v-slot:header>
            <v-card-title>
              <div class="mx-auto">Choose friend</div>
            </v-card-title>

            <v-card-text class="d-flex align-center px-12">
              <div>{{ itemDetails.name }}</div>
              <v-spacer />
              <v-img v-if="itemDetails.imageUrl" :src="itemDetails.imageUrl" width="48" height="48" contain />
            </v-card-text>
          </template>
        </FriendPickerDialog>
      </v-card>
    </div>
  </v-card>
</template>
